export const NavbarLinks = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "How to Use?",
      path: '/how-to-use',
    },
    // {
    //   title: "Pricing",
    //   path: "/pricing",
    // },
    {
      title: "Contact Us",
      path: "/contact-us",
    },
  ];
  