import React from 'react'
import { UsersList } from '../../components/Admin/UsersList'

export const Users = () => {
  return (
    <div className='flex justify-center'>
        <UsersList/>
    </div>
  )
}
