import React, { useContext, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DataContext } from '../../context/DataProvider';

export const QuilText = () => {
  const { mailContent, setMailContent } = useContext(DataContext);
  const [isHtmlMode, setIsHtmlMode] = useState(false); // Toggle between Quill and raw HTML

  // Custom toolbar options for Quill
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['blockquote', 'code-block'],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean']
    ]
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script', 'sub', 'super',
    'list', 'bullet', 'indent',
    'blockquote', 'code-block',
    'align',
    'link', 'image', 'video'
  ];

  const toggleHtmlMode = () => {
    setIsHtmlMode(!isHtmlMode);
  };

  return (
    <div className='h-[320px] w-[95%] mt-3 mx-auto'>
      <button onClick={toggleHtmlMode} className='mb-2'>
        {isHtmlMode ? 'Switch to Rich Text Editor' : 'Switch to Raw HTML'}
      </button>

      {isHtmlMode ? (
        // Raw HTML textarea
        <textarea
          value={mailContent}
          onChange={(e) => setMailContent(e.target.value)}
          className='w-full h-full border rounded p-2'
        />
      ) : (
        // ReactQuill editor
        <ReactQuill
          theme="snow"
          value={mailContent}
          onChange={setMailContent}
          modules={modules}
          formats={formats}
          className='w-full h-[80%]'
        />
      )}
    </div>
  );
};
